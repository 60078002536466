var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")]),_c('a-row',{staticClass:"flex-no__wrap wanted-search-input-wrapper",attrs:{"type":"flex"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'usefullLinks-create' })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Добавить ")],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.form,"loading":_vm.loading,"pagination":false,"scroll":{ x: 1700, y: 600 },"row-key":(record) => record.id},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('router-link',{staticClass:"td-post-item",staticStyle:{"padding":"0"},attrs:{"to":{
          name: 'usefullLinks-detail',
          params: {
            id: item.id
          }
        },"tag":"span"}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"name",fn:function(item){return [_c('router-link',{staticClass:"td-post-item",attrs:{"to":{
          name: 'usefullLinks-detail',
          params: {
            id: item.id
          }
        },"tag":"span"}},[_vm._v(" "+_vm._s(item.title || "-")+" ")])]}},{key:"link",fn:function(item){return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])]}},{key:"photo",fn:function(item){return [(!item.logo)?_c('div',[_vm._v("no photo")]):_c('div',[_c('img',{staticStyle:{"width":"50px","height":"50px","object-fit":"cover"},attrs:{"alt":"profile_img","src":item.logo}})])]}},{key:"created_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"updated_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("LLL"))+" ")]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'usefullLinks-detail',
            params: { id: item.id }
          })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),_c('a-popconfirm',{attrs:{"cancel-text":"Нет","ok-text":"Да","title":"Вы действительно хотите удалить?"},on:{"confirm":function($event){return _vm.removeItem(item.id)}}},[_c('a-button',{attrs:{"type":"danger"}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{key:_vm.limit,attrs:{"current":_vm.page,"default-page-size":_vm.limit,"total":_vm.count || 0},on:{"change":_vm.setPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }