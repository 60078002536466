<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row class="flex-no__wrap wanted-search-input-wrapper" type="flex">
        <a-button
          type="primary"
          @click="$router.push({ name: 'usefullLinks-create' })"
        >
          <a-icon type="plus" />Добавить
        </a-button>
      </a-row>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="form"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1700, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <router-link
          :to="{
            name: 'usefullLinks-detail',
            params: {
              id: item.id
            }
          }"
          style="padding: 0"
          class="td-post-item"
          tag="span"
        >
          <b>{{ item.id }}</b>
        </router-link>
      </template>

      <template slot="name" slot-scope="item">
        <router-link
          :to="{
            name: 'usefullLinks-detail',
            params: {
              id: item.id
            }
          }"
          class="td-post-item"
          tag="span"
        >
          {{ item.title || "-" }}
        </router-link>
      </template>

      <template slot="link" slot-scope="item">
        <a :href="item.link" target="_blank">{{ item.link }}</a>
      </template>

      <template slot="photo" slot-scope="item">
        <!--<spinner v-if="loading" />-->
        <div v-if="!item.logo">no photo</div>
        <div v-else>
          <img
            style="width: 50px; height: 50px; object-fit: cover"
            alt="profile_img"
            :src="item.logo"
          />
        </div>
      </template>
      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({
              name: 'usefullLinks-detail',
              params: { id: item.id }
            })
          "
        >
          <a-icon class="action-btns" type="edit" />
          <!--{{ $t("Edit") }}-->
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
          <!-- <a-icon class="action-btns" style="margin: 0 25px" type="delete" />-->
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="limit"
        :current="page"
        :default-page-size="limit"
        :total="count || 0"
        @change="setPage"
      />
    </a-row>
    <!-- <a-pagination
      style="margin-top: 20px"
      :current="+$route.query.page || 1"
      :total="(form && +form.items_count) || 0"
      :page-size="+$route.query.limit || 12"
      @change="setPage"
    />-->
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      loading: false,
      columns: [
        {
          title: "ID",
          key: "id",
          width: 80,
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TableTitle"),
          key: "name",
          width: "50%",
          scopedSlots: { customRender: "name" }
        },
        {
          title: this.$t("Link"),
          key: "link",
          width: "50%",
          scopedSlots: { customRender: "link" }
        },
        {
          title: this.$t("TablePhoto"),
          key: "photo",
          width: "20%",
          scopedSlots: { customRender: "photo" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      page: 1,
      limit: 10,
      offset: 0,
      count: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  // async created() {
  //   this.fetchData()
  // },
  methods: {
    setPage(pageNumber) {
      this.$router.push({
        query: { ...this.$route.query, page: pageNumber }
      })
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.limit
      try {
        const form = await this.$store.dispatch(
          "usefullinks/fetchUsefullinks",
          {
            params: {
              category: this.$route.params.cat_id,
              limit: this.limit,
              offset: this.offset
            }
          }
        )
        this.form = form.results
        this.count = form.count
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    async removeItem(id) {
      this.loading = true
      try {
        const r = await this.$api.delete(
          `/admin/common/usefullink/${id}/delete/`
        )
        if (r) {
          this.$message.success("Полезная ссылка успешно удалена")
          await this.fetchData()
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.wanted-search-input-wrapper {
  justify-content: flex-end;
}
</style>
